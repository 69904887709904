.homeContainer {
  background-image: url("../../assets/images/HomeBG.png");
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  z-index: -1;
  background-origin: content-box;
  padding-top: 100px;
}
.homeButtons {
  width: 210px;
  height: 210px;
  cursor: pointer;
}
