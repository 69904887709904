.nav_container {
  background-image: url("../../assets/images/banner.png");
  background-size: contain;
  background-position: unset;
  min-height: 100px;
}
.logoContainer {
  z-index: 999 !important;
  top: 0;
  width: 100%;
  /* position: fixed; */
}

.logoContainer a#navbarScrollingDropdown {
  color: white !important;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.logoContainer a#profileDropdown::after {
  border: none;
}

.logoContainer button.navbar-toggler.collapsed,
button.navbar-toggler {
  background: #faa61a;
  margin-right: 20px;
}

.logoImg {
  height: 100%;
  width: 75%;
}

@media only screen and (max-width: 991px) {
  .navBarHead {
    margin-top: 10px;
  }
  .logoImg {
    width: 100%;
    margin-left: 1rem;
    float: left;
  }
  .profileImg .dropdown-menu.show {
    margin-top: 18px !important;
    margin-left: 20px !important;
  }
}

@media only screen and (max-width: 575px) {
  .logoImg {
    width: 50% !important;
  }
}

@media only screen and (max-width: 480px) {
  .logoContainer button.navbar-toggler.collapsed,
  button.navbar-toggler {
    margin-left: auto;
    margin-top: -60px;
  }
}

.profileImg {
  position: relative;
  top: 10px;
  right: 25px;
}

/* .profileImg {
  background-image: url("../../assets/logos/ri_40.png");
  background-size: contain;
  background-position: unset;
  background-repeat: no-repeat;
  height: 60px;
  width: 100px;
} */

.nav_link {
  color: white;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}

.nav_link:hover {
  color: #000;
}

/* .bannerImg {
  width: 100%;
  height: 15vh;
  position: absolute;
  z-index: -220000;
  opacity: 85%;
} */

.navElements {
  width: 100%;
}

.f20 {
  font-size: 20px !important;
}

.logoContainer .dropdown-item:active {
  background: transparent !important;
}

.dropdown .dropdown-menu{
  /* background-color: #ffec99; */
  text-align: start;
  text-indent: 1rem;
}
.dropdown-menu:hover{
  background-color: #f8f9fa;
}
