body {
  /* font-family: "fantasy", sans-serif; */
  font-family: "Segoe UI", sans-serif;
  color: rgb(77, 77, 77);
  overflow: auto;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #e1e1e1;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}

.primary .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: black !important;
  background-color: #faa61a !important;
  border-color: #faa61a !important;
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width)) !important;
  background-color: #e1e1e1 !important;
  border: 1px solid #fff !important;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: rgba(77, 77, 77) !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.a {
  background-color: white;
  border: 3px solid#FAA61A;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.a1 {
  background-color: white;
  border: 3px solid#FAA61A;

  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.a .nav-item .nav-link {
  width: 100%;
  height: 75px;
}

.parentContainer {
  background-image: url("./assets/images/bg.webp");
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 95vh;
  z-index: -1;
  background-origin: content-box;
  padding-top: 0px;
}
.investTab {
  padding: 5px 100px;
}
.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.buttonGroup .btn-outline-warning {
  --bs-btn-color: #faa61a;
  --bs-btn-border-color: #faa61a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #faa61a;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: rgba(77, 77, 77);
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
  width: 100px;
}
.buttonGroup .btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #faa61a;
  --bs-btn-border-color: rgba(77, 77, 77);
  --bs-btn-hover-color: rgba(77, 77, 77);
  --bs-btn-hover-bg: #faa61a;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: rgba(77, 77, 77);
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(77, 77, 77);
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
  width: 100px;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
}

.inputGroup label {
  padding: 2px 4px;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  overflow: hidden;
  border: 2px solid #faa61a;
}

.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  background-color: #faa61a;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  z-index: -1;
}

.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  cursor: pointer;
}

.inputGroup input:checked ~ label {
  color: #fff;
  border: none;
  padding: 12px 20px;
}

.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.mainBody {
  background: url("./assets/images/mainBG.png");
  background-size: cover;
  padding-top: 60px;
  background-origin: content-box;
  background-repeat: no-repeat;
}

.logBtn {
  background: #4d4d4d !important;
  border: solid #4d4d4d !important;
  box-shadow: inset 0 0 0 3px #cccccc !important;
}
.btnBtn {
  background: #fc9200 !important;
}

.signupBtn {
  background: #faa61a !important;
  border: solid #faa61a !important;
  box-shadow: inset 0 0 0 3px #4d4d4d !important;
}

.navSignupBtn {
  background: white !important;
  border: solid white !important;
  box-shadow: inset 0 0 0 1px #faa61a !important;
  color: #faa61a !important;
  padding: 5px 20px !important;
}

.mainCardBorder {
  border-color: #faa61a !important;
  border-width: 15px !important;
  border-style: solid !important;
}

.txtIndent {
  text-indent: 0px;
}

.mlAuto {
  margin-left: auto;
  margin-right: auto;
}

.reviewHeading {
  width: "200px";
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #eee;
  border: 1px solid #eee;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border: 1px solid #ccc;
  height: 100px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: gray;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #bbb;
  border-radius: 10px;
}

.MuiTableCell-head {
  font-weight: bold !important;
  background: #faa61a !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fc9200;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fc9200 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #fc9200;
  color: #fff;
  font-size: 1em;
}
.commonBtn {
  width: 130px !important;
  height: 40px !important;
  font-size: 22px !important;
}

.commonIcon {
  font-size: 24px !important;
  margin-top: 2px !important;
  margin-left: 0px !important;
}

.userPic {
  position: absolute;
  bottom: 10px;
}
.userName {
  position: relative;
  top: 37px;
}
header {
  display: flex;
  justify-content: center;
  align: center;
  padding: 15px;
  background: #faa61c;
  border-bottom: 3px solid #faa61c;
}

header h1 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}
.hex3 {
  height: 170px;
  width: 75%;
  background: #dd8d19;
  clip-path: polygon(6% 5%, 94% 5%, 100% 50%, 94% 95%, 6% 95%, 0% 50%);
  margin-top: -85px;
  margin-left: auto;
  margin-right: auto;
}
.he {
  width: 51%;
  margin: auto;
  padding-top: 55px;
}
.projectsFooter {
  height: 150px;
  width: 100%;
  background: #4d4d4d;
  color: #fff;
}

.projectsImage {
  width: 100%;
  max-height: 50%;
}
projectsDetails {
  background-position: top 10px left 10px, top 10px right 10px,
    bottom 10px right 10px, bottom 10px left 10px;

  background-size: 86px;
  background-repeat: no-repeat;

  background-image: url("./assets/projects/dots2.png"),
    url("./assets/projects/dots1.png");
}

.contactUsContainer {
  width: 100%;
  minheight: 100vh;
  background-image: url("https://dev.berighthere.com/wp-content/uploads/2022/02/contact-page_dark1.png");
  background-size: cover;
}

.partner-field {
  background-color: transparent;
  background-image: linear-gradient(180deg, #fc9200 0%, #fc9200 100%);
  padding: 20px;
}
.contact-form .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 5px solid #fff;
  appearance: none;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.contact-form .form-control::before,
::placeholder {
  font-family: arial;
  font-style: italic;
  font-size: 0.75rem;
  padding: 5px;
  line-height: 1;
  box-sizing: border-box;
}

::placeholder {
  color: white !important;
  background: transparent;
}

.contact-form .form-control {
  position: relative;
}

.contact-form .form-control::before {
  content: "some other placeholder very long";
  position: absolute;
  inset: 0 5px;
  z-index: 1;
  color: mintcream;
  background: thistle;
  width: calc(100% - 10px);
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}
input[type="SUBMIT"]:active {
  background-color: #ffd8a8;
}
.form-control:focus {
  border-color: none;
}
.faIcons {
  fill: #dd8d19;
  color: #dd8d19;
  border-color: #dd8d19;
  margin: 10px;
}
.faqsContainer {
  min-height: 100vh;
  background-image: url("./assets/projects/faqbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.faqFooterContent {
  min-height: 150px;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px 2px #ce8814;
}

.footerHeading {
  color: #faa619;
  font-weight: bold;
  font-size: 16px;
}
.footer_sub_heading {
  font-size: 14px;
  letter-spacing: 0.5px;
}

.landingHeading {
  font-size: 40px;
  font-weight: lighter;
  font-family: fantasy;
  letter-spacing: 2px;
  line-height: 30px;
}

.workspaceContainer .workspaceImage {
  width: 100%;
}
.workspaceContainer {
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutContainer .workspaceImage {
  width: 100%;
}
.aboutContainer {
  min-height: 110vh;
  background-color: #525659;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mapSectionContainer .workspaceImage {
  width: 80%;
}
.mapSectionContainer {
  min-height: 110vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.moneyTrailContainer .workspaceImage {
  width: 100%;
}
.moneyTrailContainer {
  min-height: 110vh;
  background-color: #525659;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sampleCalContainer {
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mapFont {
  font-size: 4rem;
}
.joinContainer {
  margin-top: 130px;
  margin-bottom: -150px;
}

/* ---------------- LAKSHMANAN ----------------------------- */

.white_color {
  color: #fff;
}

/* ---------------- LAKSHMANAN ----------------------------- */

/* ---------------------------- NEW --------------------------------------------- */
.home_bg_container {
  background: url("./assets/images/mainBG.png");
  background-size: 100% 100%;
  background-origin: content-box;
  background-repeat: no-repeat;
}

.text_container {
  padding-top: 2rem;
  text-align: center;
  margin-bottom: -85px;
}
.landingHeading {
  font-size: 40px;
  font-weight: 1000;
  font-family: sans-serif;
  letter-spacing: 3px;
  line-height: 25px;
}
.quoted_text {
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.4px;
}

.home_page_right_upper_box {
  padding-top: 1rem;
  text-align: center;
  color: #fff;
}
.img_fluid_home_ad {
  max-width: 100%;
}
.right_upper_box_top {
  padding-bottom: 2rem;
  letter-spacing: 0.8px;
}
.home_investor_logIn_para {
  font-size: 16px;
  font-weight: 300;
}
.home_investor_logIn_head {
  font-size: 24px;
  font-weight: 800;
}
.right_upper_box_bottom {
  letter-spacing: 0.8px;
}
.home_right_input {
  max-width: 250px;
}

/* ---------------------- REVOLUTION -------------------------- */
.text_container_bottom {
  margin-top: 5rem;
}
.heading_main {
  color: #fe8f11;
  text-align: center;
  padding: 2rem 0;
  font-weight: 700;
  letter-spacing: 1px;
}
.section_revolution {
  padding-bottom: 2rem;
}
.revolution_para_head {
  color: #212529;
  text-align: justify;
  font-size: 16px;
  font-weight: 600;
}
.revolution_para {
  color: #495057;
  text-align: justify;
  font-size: 14px;
}
/* ---------------------- REVOLUTION -------------------------- */
/* ---------------------- ABOUT -------------------------- */
.container_about {
  background-color: #525659;
}
.section_about {
  padding-bottom: 2rem;
}
.about_quotes {
  font-size: 19px;
  color: rgb(255, 169, 77);
}
.about_heading_main {
  color: #fe8f11;
  text-align: center;
  padding: 1rem 0;
  font-weight: 700;
  letter-spacing: 1px;
}
.about_para_head {
  color: #f59f00;
  font-weight: 600;
  font-size: 16px;
  text-align: justify;
}
.about_para {
  color: #fff;
  text-align: justify;
  font-size: 14px;
}
/* ---------------------- ABOUT -------------------------- */
/* ------------------------- MONEY -------------------------- */
.container_money {
  background-color: #525659;
}
.section_money {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.money_heading_main {
  color: #fe8f11;
  text-align: center;
  padding: 1rem 0;
  font-weight: 700;
  letter-spacing: 1px;
}
.money_subHeading {
  color: #fff;
  letter-spacing: 1px;
}
.money_para_head {
  color: #f59f00;
  font-weight: 600;
  font-size: 16px;
  text-align: justify;
}
.money_para {
  color: #fff;
  text-align: justify;
  font-size: 14px;
}
/* ------------------------- MONEY -------------------------- */
/* ------------------------- MAP -------------------------- */
.map_heading_main {
  color: #fe8f11;
  text-align: center;
  padding: 3rem 0 0 0;
  font-weight: 700;
  letter-spacing: 1px;
}
.map_imp_text_upper {
  color: #f59f00;
  font-weight: 800;
  font-size: 3rem;
}
.map_imp_text_lower {
  color: #495057;
  font-weight: 800;
  letter-spacing: 1px;
}
.map_location_head {
  color: #212529;
  letter-spacing: 1px;
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.map_location_para {
  color: #495057;
  padding-bottom: 0.2rem;
  font-size: 14px;
}
.map_syllabify {
  color: orange;
}
.section_map {
  padding-bottom: 2rem;
}
.map_imp_text_gap {
  --bs-gutter-y: 0.5rem !important;
}
/* ------------------------- MAP -------------------------- */
/* ------------------------- SAMPLE -------------------------- */
.section_sample {
  padding: 1rem 0 2rem 0;
}
.sample_heading_main {
  color: #fe8f11;
  text-align: start;
  padding: 1rem 0;
  font-weight: 700;
  letter-spacing: 1px;
}
.sample_subHeading {
  color: #212529;
  font-size: 20px;
  text-align: justify;
  font-weight: 600;
}
.sample_list {
  list-style: url("../src/assets/images/arrow_icon.png");
}
.sample_list li {
  font-size: 18px;
  color: #495057;
  padding-bottom: 0.5rem;
  text-align: start;
  padding-left: 0.5rem;
}
.sample_list_icon {
  padding-left: 5px;
  padding-right: 0.5rem;
  font-size: 14px;
  color: rgb(255, 169, 77);
}
.sample_table {
  text-align: center;
  font-size: 14px;
}
.table_margin_from_tablet {
  margin-top: 0;
}
/* ------------------------- SAMPLE -------------------------- */
/* ------------------------- FAQ -------------------------- */
.container_faq {
  margin: 0 5px;
}
.footer_outer_container {
  border: 1rem solid #fc9200;
  /* padding: 15px; */
}
.footer_border_box_shadow {
  padding: 15px;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 25%);
  border: 1px solid #adb5bd;
}
.footer_text_icon {
  display: flex;
  align-items: center;
}
.footer_text_icon_address {
  display: flex;
  text-align: justify;
}
.footer_icon {
  color: #f59f00;
  padding-right: 10px;
}
.footer_heading {
  padding: 1rem 0;
  color: #fe8f11;
  text-align: start;
  font-weight: 800;
}
.footer_column_boder {
  border-bottom: 2px solid #fc9200;
}
.footer_links_outer {
  padding: 10px 0;
}
.footer_links {
  text-decoration: none;
  color: #fe8f11;
  font-size: 16px;
}
.footer_logo_flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_links_para {
  margin-bottom: 0.5rem;
}
.footer_links:link .footer_links:visited {
  color: #fe8f11;
}
.footer_links:hover {
  color: #e67700;
}
/* --------------------Mansoor---------- */

.orange_heading {
  font-size: 17px;
}
.orange_label {
  font-size: 16px;
}
.orange_para {
  color: #868e96;
  margin-bottom: 2px;
}

/* ------------------------- FAQ -------------------------- */

/* ---------------------------- NEW --------------------------------------------- */
/* ------------------------ RESPONSIVE ------------------------------------------------- */
@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .map_location_para {
    font-size: 16px;
    letter-spacing: 1px;
  }
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 768px) {
  .tablet_padding_view_form {
    padding-bottom: 3rem;
  }
  /* .tablet_view_style {
    row-gap: 5rem;
  } */
  .text_container {
    padding-bottom: 1.5rem;
    text-align: start;
    margin-bottom: 0;
  }
  .landingHeading {
    font-size: 32px;
    font-weight: 400;
    font-family: fantasy;
    letter-spacing: 2px;
    line-height: 16px;
  }
  .home_page_right_upper_box {
    margin-bottom: 12rem;
    text-align: start;
    margin-left: 2rem;
  }
  .text_container_bottom {
    margin-top: 2rem;
  }
  .img_fluid_home_ad {
    max-width: 125%;
  }
  .section_revolution {
    padding-bottom: 2rem;
  }
  .about_heading_main {
    padding: 2rem 0;
  }
  .money_heading_main {
    padding: 2rem 0;
  }
  .map_location_para {
    font-size: 14px;
  }
  .map_imp_text_lower {
    font-size: inherit;
  }
  .map_imp_text_gap {
    --bs-gutter-y: 0.5rem !important;
  }

  .sample_table tbody tr td {
    font-size: 20px;
    text-align: start;
    padding: 1rem;
  }
  .sample_desktop_gap {
    padding-bottom: 2rem;
  }
  .footer_heading {
    text-align: center;
  }
  .footer_links_para {
    text-align: center;
  }
  .footer_text_icon {
    justify-content: center;
  }
  .footer_column_boder {
    border: none;
    padding-left: 15px;
  }
  .footer_tablet_border_bottom {
    border-bottom: 2px solid #fc9200;
  }
  .footer_tablet_border_right {
    border-right: 2px solid #fc9200;
  }
  .footer_tablet_row_gap {
    padding-top: 10px;
  }
  .home_bg_container {
    background-size: 100% 140%;
  }
  .mlAuto {
    margin-left: 0;
    margin-right: 0;
  }
  .home_investor_logIn_head {
    font-size: 28px;
  }
  .home_right_input {
    max-width: 300px;
  }
  .home_request_button {
    min-width: 300px;
  }
  .table_margin_from_tablet {
    margin-top: 14px;
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .tablet_view_style {
    row-gap: 8rem;
  }
  .home_page_right_lower_box {
    text-align: center;
    color: #fff;
  }
  .tablet_padding_view_form {
    padding: 1rem 0;
    padding-bottom: 3rem;
  }
  .img_fluid_home_ad {
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  /* big landscape tablets, laptops, and desktops */
  .inputGroup label {
    padding: 4px 8px;
  }
  .landingHeading {
    font-size: 40px;
    line-height: 30px;
  }
  .revolution_para_head {
    font-size: 20px;
  }
  .revolution_para {
    font-size: 18px;
  }
  .section_money {
    padding-top: 2rem;
  }
  .map_imp_text_gap {
    --bs-gutter-y: 1.5rem !important;
  }
  .footer_tablet_border_bottom {
    border: none;
  }
  .footer_tablet_border_right {
    border: none;
  }
  .footer_desktop_width {
    min-height: 160px;
    border-left: 2px solid #fc9200;
  }
  .footer_tablet_row_gap {
    padding-top: 0px;
  }
  .footer_heading {
    text-align: start;
  }
  .footer_text_icon {
    justify-content: start;
  }
  .footer_links_para {
    text-align: start;
  }
  .footer_links_para {
    margin-bottom: 1.5rem;
  }
  .home_bg_container {
    background-size: 100% 135%;
  }
  .img_fluid_home_ad {
    max-width: 100%;
  }
  .home_page_right_upper_box {
    margin-bottom: 10rem;
  }
  .text_container {
    padding-bottom: 1rem;
  }
  .home_right_input {
    max-width: 350px;
  }
  .home_request_button {
    min-width: 350px;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */

  .home_page_right_upper_box {
    margin-bottom: 6rem;
  }
  .revolution_para_head {
    padding-bottom: 2rem;
  }
  .about_para_head {
    font-size: 18px;
  }
  .about_para {
    font-size: 15px;
  }
  .money_para_head {
    font-size: 25px;
  }
  .money_para {
    font-size: 20px;
  }
  .map_imp_text_lower {
    font-size: 22px;
  }
  .map_location_desktop_gap {
    margin-top: 2rem;
  }
  .map_location_para {
    font-size: 19px;
  }
  .map_imp_text_gap {
    --bs-gutter-y: 2rem !important;
  }
  .home_bg_container {
    background-size: 100% 130%;
  }
  .home_right_input {
    max-width: 400px;
  }
  .home_request_button {
    min-width: 400px;
  }
}
@media (min-width: 1440px) {
  /* hi-res laptops and desktops */
  .about_para_head {
    font-size: 20px;
  }
  .about_para {
    font-size: 17px;
  }
  .map_imp_text_gap {
    --bs-gutter-y: 3rem !important;
  }
  .home_bg_container {
    background-size: 100% 130%;
  }
  .img_fluid_home_ad {
    max-width: 70%;
  }
}

/* ------------------------ RESPONSIVE ------------------------------------------------- */

.faqFooter {
  height: 100%;
  background-color: #fc9200;
  padding: 15px;
}
.footerLine {
  display: block;
  color: #fc9200;
}
.footerInfo {
  min-width: 350px;
  font-size: 20px;
}
.footerInside {
  height: 100%;
  background-color: #ffffff;
  padding: 10px;
}
.footerContactUS {
  border-bottom: 2px solid #fc9200;
  padding: 5px;
}
.footerImage {
  border-bottom: 2px solid #fc9200;
  padding: 5px;
  display: flex;
  justify-content: center;
}
.footerAddress {
  border-bottom: 2px solid #fc9200;
  padding: 5px;
}

@media screen and (min-width: 767px) {
  .faqFooter {
    min-height: 200px;
    background-color: #fc9200;
    padding: 15px;
  }
  .footerContactUS {
    margin: auto;
    padding: 25px;
    width: 350px !important;
    height: 150px;
  }
  .footerImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px !important;
    height: 150px;
    border-right: 2px solid #fc9200;
  }

  .footerAddress {
    width: 350px !important;
    height: 150px;
    border-right: 2px solid #fc9200;
    border-top: 2px solid #fc9200;
    padding: 25px;
  }
  .footerInfo {
    display: flex;
    flex-direction: column;
    width: 350px !important;
    height: 150px;
    border-top: 2px solid #fc9200;
    padding: 25px;
  }
  .footerInside {
    height: 100%;
    background-color: #ffffff;
    padding: 10px;
  }
  .footerLine {
    display: none;
    color: #fc9200;
  }
  .vl {
    display: none;
    width: 1px;
    border-left: 4px solid #faa619;
    height: 90px;
    margin-left: -3px;
    top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .faqFooter {
    min-height: 200px;
    background-color: #fc9200;
    padding: 15px;
  }
  .footerContactUS {
    width: 242px !important;
    height: 150px;
    border-right: 2px solid #fc9200;
  }
  .footerImage {
    width: 240px !important;
    display: flex;
    height: 150px;
    justify-content: center;
    flex-direction: column;
  }

  .footerAddress {
    width: 242px !important ;
    height: 150px;
    border-top: none;
  }
  .footerInfo {
    width: 242px !important;
    height: 150px;
    border-top: none;
    display: flex;
    flex-direction: column;
  }
  .footerInside {
    height: 100%;
    background-color: #ffffff;
    padding: 10px;
  }
  .footerLine {
    display: none;
    color: #fc9200;
  }
}
@media screen and (min-width: 1440px) {
  .faqFooter {
    min-height: 200px;
    background-color: #fc9200;
    padding: 15px;
  }
  .footerContactUS {
    width: 320px !important;
    height: 150px;
    border-right: 2px solid #fc9200;
  }
  .footerImage {
    width: 320px !important;
    height: 150px;
  }

  .footerAddress {
    width: 320px !important;
    height: 150px;
    border-top: none;
  }
  .footerInfo {
    width: 320px !important;
    height: 150px;
    border-top: none;
  }
  .footerInside {
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
  }
  .footerLine {
    display: none;
    color: #fc9200;
  }
}
@media only screen and (max-width: 800px) {
  .txtIndent {
    margin-top: 20px !important;
  }

  .investTab {
    padding: 5px 10px;
  }
}
@media only screen and (min-width: 600px) {
  .MuiToolbar-root
    .MuiToolbar-regular
    .MTableToolbar-root-5
    .MuiToolbar-gutters {
    min-height: unset !important;
  }
  .expandInnerTable .MuiTableCell-head {
    background: #f7cb85 !important;
  }
}
/* @media only screen and (max-width: 500px) {
  .inputGroup label {
    padding: 7px 12px !important;
  }
} */
@media screen and (max-width: 768px) {
  .faqHeading {
    display: none;
  }
  .faqButton {
    display: none;
  }
  .faqImage {
    width: 200px;
  }
  .faqMobileHeading {
    font-size: 15px;
    font-weight: 500;
    color: #f0c781;
  }

  .faqButton2 {
    width: 160px;
    height: 40px;
    background-color: #faa619;
    border-color: #faa619;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: 2px solid #fc9200;
    margin: -12px;
    margin-top: -30px;
    margin-bottom: -26px;
  }

  .faqSection {
    padding: 3rem;
    background-color: #fff;
    min-height: 20vh;
    border: 2px solid #faa619;
    width: 100%;
  }
  .faqImage {
    width: 100%;
  }
  .faqLine {
    margin-top: 25px;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }
  .faqQuestion {
    border: 1px solid;
    padding: 5px;
    width: 100%;

    font-size: 11px;
    font-weight: 600;
  }
  .faqAnswer {
    font-size: 11px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .faqToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    min-width: 32px;
    min-height: 32px;
    border-right: 0px;
    color: #fc9200;
    font-weight: bold;
  }
  .bgDarkContainer {
    display: none;
    min-height: 150px;
    width: 100%;
  }
  .flexi {
    background-color: grey;
  }
  .fqCont {
    padding: 5px;
  }
}
@media screen and (min-width: 767px) {
  .faqHeading {
    display: block;
    width: 85%;
    font-size: 25px;
    color: #f0c781;
  }
  .faqButton {
    display: none;
    background-color: #fc9200;
    width: 100%;
    height: 50px;
    color: rgba(77, 77, 77);
  }
  .bgDarkContainer {
    display: none;
    min-height: 150px;
    width: 100%;
  }
  .faqImage {
    width: 100%;
  }
  .faqMobileHeading {
    display: none;
    font-size: 15px;
    font-weight: 500;
    color: #f0c781;
  }

  .faqButton2 {
    width: 160px;
    height: 40px;
    background-color: #faa619;
    border-color: #faa619;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: 2px solid #fc9200;
    margin: -12px;
    margin-top: -30px;
    margin-bottom: -26px;
  }

  .faqSection {
    padding: 3rem;
    background-color: #fff;
    min-height: 20vh;
    border: 2px solid #faa619;
    width: 100%;
  }
  .faqImage {
    width: 100%;
  }
  .faqLine {
    margin-top: 25px;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }
  .faqQuestion {
    border: 1px solid;
    padding: 5px;
    width: 100%;

    font-size: 20px;
    font-weight: 600;
  }
  .faqAnswer {
    font-size: 18px;

    width: 90%;
    display: flex;
    justify-content: center;

    margin-bottom: 0px;
  }
  .faqToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    width: 32px;
    min-height: 32px;
    border-right: 0px;
    color: #fc9200;
    font-weight: bold;
  }
}
@media screen and (min-width: 1024px) {
  .faqHeading {
    display: block;
    max-width: 85%;
    font-size: 25px;
    color: #f0c781;
  }
  .faqButton {
    display: block;
    background-color: #fc9200;
    width: 100%;
    font-size: 20px;
    height: 50px;
    color: rgba(77, 77, 77);
    font-weight: bold;
  }
  .bgDarkContainer {
    display: block;
    min-height: 150px;
    width: 100%;
  }
  .faqImage {
    width: 100%;
  }
  .faqMobileHeading {
    display: none;
    font-size: 15px;
    font-weight: 500;
    color: #f0c781;
  }

  .faqButton2 {
    width: 160px;
    height: 40px;
    background-color: #faa619;
    border-color: #faa619;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    border: 2px solid #fc9200;
    margin: -12px;
    margin-top: -30px;
    margin-bottom: -26px;
  }

  .faqSection {
    padding: 3rem;
    background-color: #fff;
    min-height: 20vh;
    border: 2px solid #faa619;
    width: 100%;
  }
  .faqImage {
    width: 100%;
  }
  .faqLine {
    margin-top: 25px;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }
  .faqQuestion {
    border: 1px solid;
    padding: 5px;
    width: 100%;

    font-size: 20px;
    font-weight: 600;
  }
  .faqAnswer {
    font-size: 18px;

    display: flex;
    justify-content: center;

    margin-bottom: 0px;
  }
  .faqToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    width: 32px;
    min-height: 32px;
    border-right: 0px;
    color: #fc9200;
    font-weight: bold;
  }
}
@media screen and (max-width: 480px) {
  .projectContainer {
    width: 100%;
  }
  .hex3 {
    height: 75px;
    width: 75%;
    background: #dd8d19;
    clip-path: polygon(6% 5%, 94% 5%, 100% 50%, 94% 95%, 6% 95%, 0% 50%);
    margin-top: -38px;
    margin-left: auto;
    margin-right: auto;
  }
  .he {
    font-size: 10px;
    width: 85%;
    padding-top: 25px;
  }
  .projectsDetails {
    background-position: top 125px left 10px, top 10px right 10px,
      bottom 10px right 10px, bottom 10px left 10px;
    background-size: 45px;
    background-repeat: no-repeat;
    background-image: url("./assets/projects/dots2.png"),
      url("./assets/projects/dots1.png");
  }
  .projectInformation {
    margin-bottom: 10px;
    width: 80%;
    margin: auto;
  }
  .projectDataContainer {
  }
  h3 {
    font-size: 10px;
  }
}
@media screen and (min-width: 768px) {
  .projectContainer {
    width: 100%;
  }
  .hex3 {
    height: 104px;
    width: 75%;
    background: #dd8d19;
    clip-path: polygon(6% 5%, 94% 5%, 100% 50%, 94% 95%, 6% 95%, 0% 50%);
    margin-top: -52px;
    margin-left: auto;
    margin-right: auto;
  }
  .he {
    font-size: 15px;
    width: 85%;
    padding-top: 30px;
  }
  .projectsDetails {
    background-position: top 125px left 10px, top 10px right 10px,
      bottom 10px right 10px, bottom 10px left 10px;
    background-size: 45px;
    background-repeat: no-repeat;
    background-image: url("./assets/projects/dots2.png"),
      url("./assets/projects/dots1.png");
  }
  .projectInformation {
    margin-bottom: 10px;
    width: 80%;
    margin: auto;
  }
  .projectDataContainer {
    margin-bottom: 10px;
  }
  h3 {
    font-size: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .projectContainer {
    width: 100%;
  }
  .hex3 {
    height: 150px;
    width: 75%;
    background: #dd8d19;
    clip-path: polygon(6% 5%, 94% 5%, 100% 50%, 94% 95%, 6% 95%, 0% 50%);
    margin-top: -75px;
    margin-left: auto;
    margin-right: auto;
  }
  .he {
    font-size: 20px;
    width: 75%;
    padding-top: 52px;
  }
  .projectsDetails {
    background-position: top 125px left 10px, top 10px right 10px,
      bottom 10px right 10px, bottom 10px left 10px;
    background-size: 45px;
    background-repeat: no-repeat;
    background-image: url("./assets/projects/dots2.png"),
      url("./assets/projects/dots1.png");
  }
  .projectInformation {
    margin-bottom: 10px;
    width: 80%;
    margin: auto;
    font-size: 20px;
  }
  .projectDataContainer {
    margin-bottom: 10px;
  }
  h3 {
    font-size: 15px;
  }
}
.user_profile_button {
  background-color: orange;
  border: none;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 0.5rem 3rem;
}

@media screen and (max-width: 480px) {
  .vl {
    display: none;
    border-bottom: 4px solid #faa619;
    height: 90px;
    left: 50%;
    margin-left: -3px;
    top: 0;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 480px) {
  .mapContainer {
    width: 100%;
    height: 350px;
  }
  .landShow {
    display: none;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-top: -250px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .landShow {
    display: none;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-top: -250px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .landShow {
    display: block;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-top: -250px;
  }
}
@media screen and (min-width: 1440) {
  .landShow {
    display: block;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-top: -250px;
  }
  .land .landingHeading {
    font-size: 40px;
    font-weight: lighter;
    font-family: fantasy;
    letter-spacing: 2px;
  }
}

@media screen and (min-width: 481px) {
  .mapContainer {
    width: 100%;
    height: 350px;
  }
}
@media screen and (max-width: 480px) {
  .workspaceImage {
    width: 100%;
  }
  .mapSectionContainer .workspaceImage {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .workspaceImage {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  .workspaceImage {
    width: 100%;
  }
}
@media (min-width: 768px) {
}

@media (min-width: 961px) {
}
@media screen and (max-width: 480px) {
  .joinContainer {
    margin-top: 5px;
  }
}
@media screen and (min-width: 1440px) {
  .joinContainer {
    margin-top: 350px;
    margin-bottom: -371px;
  }
  .mainBody {
    min-height: 135vh;
  }
}
input[type="password"] {
  /* background-color: red; */
}
.password_button {
  position: absolute;
  top: 2px;
  right: -16px;
  right: 1px;
  border: none;
  background: transparent;
}
.myInvestmentTotalAmount {
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
}
.delete-icon:hover {
  /* background-color: #f00;  Change the background color on hover */
  opacity: 0.5;
}
