/* @import url("https://fonts.googleapis.com/css?family=Segoe UI:300,400,600"); */
@import url("https://fonts.googleapis.com/css?family=Noto Sans:300,400,600");
@import "~react-datepicker/dist/react-datepicker.css";
@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}
body {
  font-family: "Noto Sans" !important;
  font-family: "Segoe UI", sans-serif;
}

body:not(*:root) {
  font-family: "Segoe UI", sans-serif !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* 
@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Light");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Semilight");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Semibold");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
} */

body,
html {
  margin: 0 !important;
  font-family: "Noto Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  height: 100% !important;
  width: 100% !important;
}

code {
  font-family: "Noto Sans" !important;
}

.orange {
  color: #faa61a;
}

.grey {
  color: #4d4d4d;
}

.lightgrey {
  color: #808080;
}

.lightestgrey {
  color: #cccccc;
}

.bgorange {
  background: #faa61a;
}

.bggrey {
  background: #4d4d4d;
}

.bglightgrey {
  background: #808080;
}

.bglightestgrey {
  background: #cccccc;
}
.pageNotFoundContainer {
  height: 100vh;
  background-image: url("./assets/images/HomeBG.png");
  background-size: cover;
  padding-top: 60px;
  background-origin: content-box;
  background-repeat: no-repeat;
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 16px;
}
.nav-item .nav-link {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}
label {
  display: inline-block;
  font-size: 14px;
}

.formCheckLabel label {
  display: inline-block;
  font-size: 10px;
}
.formCheckLabel .form-check-input:checked {
  background-color: #fc9200;
  border-color: #fc9200;
}

@media only screen and (max-width: 500px) {
  .formCheckLabel label {
    font-size: 12px !important;
  }
}
.h5,
h5 {
  font-size: 1.11rem;
}
@media (min-width: 1024px) {
  .formCheckLabel label {
    display: inline-block;
    font-size: 14px;
  }
}

.MuiToolbar-regular {
  min-height: 0px !important;
}
